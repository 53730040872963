import React, { useState, useEffect } from "react"
import { DateRange } from "react-date-range"
import { Container, Row, Col, Form, Button } from "react-bootstrap"
import { format, getDate } from "date-fns"
import "react-date-range/dist/styles.css" // main css file
import "react-date-range/dist/theme/default.css" // theme css file
import "./assets/styles/_index.scss"
import clsx from "clsx"
import loadable from "@loadable/component"
import { Link, navigate } from "gatsby"
import {
  axiosInstance,
  axiosInstance1,
  getAvailableDates,
  getBookingPriceDetails,
  isDateInArray,
  numberFormat,
} from "../../utils/common/utils"
import ReservationModule from "../ReservationModule/ReservationModule"
import { PageLinks } from "../../common/site/page-static-links"
import CurrencyPrice from "../CurrencyPrice/CurrencyPrice"
import AdultsList from "../../search_config/adults.json"
import ChildrenList from "../../search_config/children.json"
import CurrencyPriceNoSYmbol from "../CurrencyPrice/CurrencyPriceNoSYmbol"

const Select = loadable(() => import("react-select"))
const PropertyDetailNego = (props) => {
  // Dropdown react select styles
  const customStyles = {
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        // backgroundColor: isSelected ? "#081D3C" : "null",
        // color: isSelected ? "#2F3237" : "#34373D",
        "&:hover": {
          // color: "#2F3237",
          cursor: "pointer",
          // backgroundColor: "#081D3C",
        },
      }
    },
    control: (styles) => ({
      ...styles,
      backgroundColor: null,
      border: "1px solid #ECEDEE",
      paddingLeft: "12px",
      paddingRight: "12px",
      outline: 0,
      boxShadow: "none",
      color: "#2F3237",
      fontSize: "1rem",
    }),
    valueContainer: (styles) => ({
      ...styles,
      fontSize: "1rem",
      paddingLeft: "12px",
      paddingRigth: "12px",
      lineHeight: "48px",
      cursor: "pointer",
    }),
    dropdownIndicator: (styles) => ({
      ...styles,
      color: "#2F3237",
    }),
    indicatorsContainer: (styles) => ({
      ...styles,
      color: "#2F3237",
      cursor: "pointer",
    }),
    indicatorSeparator: () => null,
    placeholder: (defaultStyles) => {
      return {
        ...defaultStyles,
        color: "#2F3237",
        marginLeft: 0,
      }
    },
  }
  // Dropdown react select styles
  const { property } = props
  const [showModal, setShowModal] = useState(false)
  const [showDesc, setShowDesc] = useState(false)
  const [showDatePicker, setShowDatePicker] = useState(false)
  const [startDate, setStartDate] = useState("")
  const [endDate, setEndDate] = useState("")
  const [guestState, setGuestState] = React.useState(false)
  const [closeState, setCloseState] = React.useState(false)
  const [adultCount, setAdultCount] = React.useState({ "value": 1, "label": "1" })
  const [childrenCount, setChildrenCount] = React.useState({ "value": 0, "label": "0" })
  const [infantCount, setInfantCount] = React.useState(0)
  const [totalCount, setTotalCount] = React.useState(0)
  const [startDisplayDate, setStartDisplayDate] = useState("")
  const [endDisplayDate, setEndDisplayDate] = useState("")
  const [showPriceDetail, setShowPriceDetail] = useState(false)
  const showDetailPrice = () => {
    setShowPriceDetail(!showPriceDetail)
  }
  const minusAdults = (e) => {
    setAdultCount(e)
    checkAvailability()

  }
  const plusAdults = () => {
    setAdultCount(adultCount + 1)
    // checkAvailability()
  }
  const minusChildren = (e) => {
    setChildrenCount(e)
    checkAvailability()
  }
  const plusChildren = () => {
    setChildrenCount(childrenCount + 1)
    // checkAvailability()
  }
  const handleDateChange = (range) => {
    // console.log("range =>", range)
    setDateRange(range.selection)
    const start = formatDate(range.selection.startDate)
    const end = formatDate(range.selection.endDate)

    const startDispaly = formatDateDisplay(range.selection.startDate)
    const endDispaly = formatDateDisplay(range.selection.endDate)

    setStartDate(start)
    setEndDate(end)

    setStartDisplayDate(startDispaly)
    setEndDisplayDate(endDispaly)
    if (start && end && end !== start) {
      setShowDatePicker(false)
      // checkAvailability()
    }
  }
  useEffect(() => {
    if (startDate && endDate && startDate !== endDate) {
        // setShowDatePicker(false)
        checkAvailability()
    }
}, [startDate, endDate]);
  const [dateRange, setDateRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
  })
  const formatDate = (date) => {
    const formatedDate = format(new Date(date), "yyyy-MM-dd")
    return formatedDate
  }
  const formatDateDisplay = (date) => {
    const formatedDate = format(new Date(date), "dd-MM-yyyy")
    return formatedDate
  }
  useEffect(() => {
    setTotalCount(parseInt(adultCount?.value) + parseInt(childrenCount?.value) + infantCount)
    
  }, [adultCount, childrenCount, infantCount])
//   useEffect(() => {
//     if(!showDatePicker){
//       checkAvailability()
//     }
// }, [showDatePicker]);
  // get unavailable dates

  const [disabledDates, setDisabledDates] = useState([])
  const [errorMsg, SetErrorMsg] = useState("")
  const [checkingAvailability, setCheckingAvailability] = useState(false)
  const [isAvailable, setIsAvailable] = useState(false)
  const [bookingReqData, setBookingReqData] = useState(null)
  const [paymentData, setPaymentData] = useState(null)
  const [bookingDisplayData, setBookingDisplayData] = useState(null)
  const gocart = () => {
    if (isAvailable) {
      // setShowModal(true)
      if (typeof window !== "undefined") {
      window.open(`${
        property?.urls?.length > 0
          ? property?.urls[0]?.BookingURL?.value
          : null
      }?FRMEntrada=${format(new Date(startDate), "dd/MM/yyyy")}&FRMSalida=${format(new Date(endDate), "dd/MM/yyyy")}&FRMAdultos=${totalCount}`,
    '_blank'
  )}
      // navigate(
      //   `${
      //     property?.urls?.strapi_json_value?.length > 0
      //       ? property?.urls?.strapi_json_value[0]?.BookingURL?.value
      //       : null
      //   }?FRMEntrada=${format(new Date(startDate), "dd/MM/yyyy")}&FRMSalida=${format(new Date(endDate), "dd/MM/yyyy")}&FRMAdultos=${totalCount}`
      // )
    }
  }
  const checkAvailability = async () => {
    if (props?.mobview) {
      props?.setMobileTog(true)
    }
   
    setGuestState(false)
    
    if (
      startDate === "" &&
      endDate === "" &&
      startDisplayDate === "" &&
      endDisplayDate === ""
    ) {
      SetErrorMsg("Please choose a valid check-in and check-out date.")
      return
    }
    if (!totalCount || totalCount < 1) {
      SetErrorMsg("Please add atleast 1 guest.")
      return
    }
    if (adultCount?.value === 0) {
      SetErrorMsg("Please add atleast 1 adults.")
      return
    }
    SetErrorMsg(null)

    setCheckingAvailability(true)

    const availabilityReqData = {
      accommodationCode: property?.crm_id,
      userCode: property?.userCode,
      accommodationMasterCode: property?.accommodationMasterCode,
      loginGA: property?.loginGA,
      adultsNumber: totalCount,
      child1_Age: "6",
      dateFrom: startDate,
      dateTo: endDate,
    }
    const availabilityReqData1 = {
      accommodationCode: property?.crm_id,
      userCode: property?.userCode,
      accommodationMasterCode: property?.accommodationMasterCode,
      loginGA: property?.loginGA,
      adultsNumber: totalCount,
      child1_Age: "6",
      dateFrom: startDate,
      dateTo: endDate,
      arrivalDate: startDate,
      departureDate: endDate,
    }

    setBookingReqData(availabilityReqData)

  

    try {
      const res = await axiosInstance.post(
        "/api/stb-feed-avantio/isAvailability",
        availabilityReqData
      )
      // console.log(
      //   "🚀 ~ file: HolidayContactDetails.js:78 ~ checkAvailability ~ data",
      //   res
      // )

      if (res.status === 200) {
        if (res.data?.Available?.AvailableCode === "1") {
     
          const reservationData = await axiosInstance.post(
            "/api/stb-feed-avantio/getBookingPrice",
            availabilityReqData1
          )
         
          if (reservationData?.status === 200) {
            setCheckingAvailability(false)
            const bookingData = getBookingPriceDetails(reservationData.data, {
              startDate,
              endDate,
            })

          
            if (
              isNaN(bookingData.totalPrice) ||
              parseInt(bookingData.totalPrice) < 1
            ) {
              SetErrorMsg(
                "Something went wrong, please try again with different dates."
              )
              setIsAvailable(false)
              return
            }

            setPaymentData({
              totalPrice: bookingData.totalPrice,
              currency: bookingData.currency,
            })
            setBookingDisplayData(bookingData.displayData)

            setIsAvailable(true)
          } else {
            setCheckingAvailability(false)
            if (reservationData?.status === 204) {
              SetErrorMsg("No data found")
            } else {
              SetErrorMsg("Something went wrong")
            }

            setIsAvailable(false)
          }
        } else {
          SetErrorMsg(res.data?.Available?.AvailableMessage || "Not Available")
          setCheckingAvailability(false)
          setIsAvailable(false)
        }
      }
       
    } catch (error) {
      console.error("🚀 ~  checkAvailability ~ error", error)
      setCheckingAvailability(false)
    } 
  }

  useEffect(() => {
    if (adultCount?.value) {
      const bedroom_key = Object.keys(AdultsList).find(
        (key) => AdultsList[key].value === adultCount?.value
      )
      setAdultCount(AdultsList[bedroom_key])
    }
    const availableDates = getAvailableDates(
      property?.availabilities
    )
    const allDatesTillOneYear = []
    const now = new Date()
    for (let i = 0; i < 365; i++) {
      allDatesTillOneYear.push(new Date(now.setDate(now.getDate() + 1)))
    }

    const unavailableDates = []
    if (availableDates?.length > 0) {
      allDatesTillOneYear.forEach((date) => {
        if (!isDateInArray(availableDates, date)) {
          unavailableDates.push(date)
        }
      })
    }

    setDisabledDates(unavailableDates)

    return () => {}
  }, [])

  const getPriceValue = (data) => {
    if (Array.isArray(data)) {
      // For Type 2 data
      return data.map((item) => {
        return {
          startDate: item.StartDate?.value,
          endDate: item.EndDate?.value,
          price: parseFloat(item.RoomOnly?.Price?.value),
          currency: item?.Currency?.value,
        }
      })
    }
    // For Type 1 data
    return [
      {
        startDate: data.StartDate?.value,
        endDate: data.EndDate?.value,
        price: parseFloat(data.RoomOnly?.Price?.value),
        currency: data?.Currency?.value,
      },
    ]
  }
  const renderDayContents = (date, data) => {
    const priceData = getPriceValue(data)

    // Find the price for the given date
    const priceInfo = priceData.find((item) => {
      const startDate = new Date(item.startDate)?.setHours(0, 0, 0, 0)
      const endDate = new Date(item.endDate)?.setHours(0, 0, 0, 0)
      const currentDate = new Date(date)?.setHours(0, 0, 0, 0)

      return currentDate >= startDate && currentDate <= endDate
    })

    if (priceInfo?.price) {
      return (
        <>
          <span className="date">{getDate(date)}</span>
          <span className="cal-text">
            <CurrencyPrice price={priceInfo?.price} />
          </span>
        </>
      )
    }
    return (
      <>
        <span className="date">{getDate(date)}</span>
        {/* <span className="cal-text">Price not available</span> */}
      </>
    )
  }

  // console.log("property_rates", property?.rates?.strapi_json_value)

  return (
    <div className="property-detail-nego-wrapper">
      <div className="property-detail-nego-top">
        {props?.mobview && props?.mobiletog && (
          <div className="ct-ck">
            <a
              className="close-btn-icon"
              href="javascript:void(0)"
              onClick={() => props?.setMobileTog(false)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <path
                  d="M3.75 16.25L16.25 3.75M3.75 3.75L16.25 16.25"
                  stroke="#1C2128"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </a>
          </div>
        )}
        <div className="price-bk d-flex">
          <div className="price d-flex d-lg-block align-items-center">
            <div className="aed">
              {/* <CurrencyPrice price={property?.price} /> */}
              <CurrencyPriceNoSYmbol price={property?.price} />
            </div>
            <div className="per">per night</div>
          </div>
          {/* {property?.extra?.reviews &&
                    <div className="reviews-data d-flex">
                        <i className="icon icon-review" /> <span className="rate">{property?.extra?.reviews}</span> <span className="count">· {property?.extra?.reviews} Reviews</span>
                    </div>
                    } */}
        </div>
        {props?.mobiletog && (
          <div>
            {/* <div className="info-bk">
                    <div className="info-cancel">
                        Free cancellation <i className="icon icon-info" onClick={() => setShowDesc(!showDesc)}></i>
                        {showDesc && <p className="search-results-desc-text">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.</p>}
                    </div>
                    <div className="date">Before Wed, Sep 27</div>
                </div> */}
            <div className="date-range">
              <div
                className={showDatePicker ? "datechoose active" : "datechoose"}
                onClick={() => {
                  setShowDatePicker((prev) => !prev)
                  // setGuestState(false)
                }}
              >
                <div className="d-flex date">
                  <div className="date-view">
                    <Form.Label>Check in</Form.Label>
                    <div className="label">
                      {startDisplayDate || "Choose Date"}
                    </div>
                  </div>
                  <div className="date-view">
                    <Form.Label>Check out</Form.Label>
                    <div className="label">
                      {endDisplayDate || "Choose Date"}
                    </div>
                  </div>
                </div>
              </div>
              {showDatePicker && (
                <div className="date-picker">
                  <DateRange
                    className="myDatePicker"
                    editableDateInputs
                    onChange={handleDateChange}
                    moveRangeOnFirstSelection={false}
                    retainEndDateOnFirstSelection={false}
                    ranges={[dateRange]}
                    months={1}
                    direction="horizontal"
                    dateDisplayFormat="dd-MM-yyyy"
                    showDateDisplay={false}
                    minDate={new Date()}
                    disabledDates={disabledDates}
                    // ariaLabels="190"
                    rangeColors={["#FCBF2F", ["#000"]]}
                    color="#FCBF2F"
                    dayContentRenderer={(e) =>
                      renderDayContents(
                        e,
                        property?.rates?.length > 0
                          ? property?.rates
                          : property?.rates
                      )
                    }
                  />
                </div>
              )}
            </div>
            <div className="guest-btn d-block">
              <div
                className="guest-number d-flex"
                onClick={() => {
                  setGuestState((prev) => !prev)
                  setShowDatePicker(false)
                }}
              >
                <Form.Label>Guests </Form.Label>
                <i
                  className={
                    guestState
                      ? "icon-select-dropdown active"
                      : "icon-select-dropdown "
                  }
                />
              </div>
              <div
                onClick={() => {
                  setGuestState((prev) => !prev)
                  setShowDatePicker(false)
                }}
                className="label"
              >
                {totalCount || "Add"} Guests
              </div>
              <div
                className={
                  guestState
                    ? "guest-dropdown d-block"
                    : "guest-dropdown d-none"
                }
              >
                <div className="guest-wrap">
                  <div className="ltl-guest">
                    <span className="guest-category">Adults</span>
                    <span className="guest-description">
                      (Ages 16 or above)
                    </span>
                  </div>
                  <div className="rtl-guest">
                  <Select
                            options={AdultsList}
                            // defaultValue={AdultsList[bedroom_key]}
                            value={adultCount}
                            placeholder="Adults"
                            name="Adults"
                            className="select-control"
                            classNamePrefix="react-select"
                            styles={customStyles}
                            isSearchable={false}
                            components={{
                              DropdownIndicator: () => (
                                <i
                                  className={`${
                                    // showFilter === true
                                    //   ? "icon icon-select-dropdown-dark"
                                      // : 
                                      "icon icon-select-dropdown"
                                  }`}
                                />
                              ),
                              IndicatorSeparator: () => null,
                            }}
                            onChange={(e)=>minusAdults(e)}
                          />
                    {/* <div
                      className="minus-btn"
                      onClick={() =>
                        adultCount > 1 && minusAdults()
                      }
                    >
                      -
                    </div>
                    <span className="btn-value">{adultCount}</span>
                    <div
                      className="plus-btn"
                      onClick={() => plusAdults()}
                    >
                      +
                    </div> */}
                  </div>
                </div>
                <div className="guest-wrap">
                  <div className="ltl-guest">
                    <span className="guest-category">Children</span>
                    <span className="guest-description">
                      (Ages 0-12 or above)
                    </span>
                  </div>
                  <div className="rtl-guest">
                  <Select
                            options={ChildrenList}
                            // defaultValue={pathChange ? '' : bedroom_options[bedroom_key]}
                            value={childrenCount}
                            placeholder="Children"
                            name="Children"
                            className="select-control"
                            classNamePrefix="react-select"
                            styles={customStyles}
                            isSearchable={false}
                            components={{
                              DropdownIndicator: () => (
                                <i
                                  className={`${
                                    // showFilter === true
                                    //   ? "icon icon-select-dropdown-dark"
                                      // : 
                                      "icon icon-select-dropdown"
                                  }`}
                                />
                              ),
                              IndicatorSeparator: () => null,
                            }}
                            onChange={(e)=>minusChildren(e)}
                          />
                    {/* <div
                      className="minus-btn"
                      onClick={() =>
                        childrenCount > 1 && minusChildren()
                      }
                    >
                      -
                    </div>
                    <span className="btn-value">{childrenCount}</span>
                    <div
                      className="plus-btn"
                      onClick={() => plusChildren()}
                    >
                      +
                    </div> */}
                  </div>
                </div>
                {/* <div className="guest-wrap">
                                    <div className="ltl-guest">
                                        <span className="guest-category">Infants</span>
                                        <span className="guest-description">(Under 2)</span>
                                    </div>
                                    <div className="rtl-guest">
                                        <div className="plus-btn" onClick={() => (infantCount > 0 && setInfantCount(infantCount - 1))}>-</div>
                                        <span className="btn-value">
                                            {infantCount}
                                        </span>
                                        <div className="minus-btn" onClick={() => setInfantCount(infantCount + 1)}>+</div>
                                    </div>
                                </div> */}
              </div>
            </div>
            {bookingDisplayData &&
              (!showPriceDetail ? (
                <div className="total-bk d-flex">
                  <div className="total left">
                    <div className="total-txt">Total</div>
                    <div className="sm-content">
                      Total includes fees, not tax
                    </div>
                  </div>
                  <div className="total right">
                    <div className="total-txt">
                    <CurrencyPrice price={paymentData?.totalPrice} />
                      {/* {`${numberFormat(
                      paymentData?.totalPrice
                    )} ${paymentData?.currency} `} */}
                    </div>
                    <a
                      href="javascript:void(0)"
                      onClick={() => showDetailPrice()}
                      className="sm-content"
                    >
                      Price details
                    </a>
                  </div>
                </div>
              ) : (
                <div>
                  <div className="booking-info-wrap">
                    {bookingDisplayData.map((data) => (
                      <div className="info-row">
                        <div className="label">{data[0]}</div>
                        <div className="value">{data[1]}</div>
                      </div>
                    ))}
                  </div>
                  <div className="total-bk no-top d-flex">
                    <div className="total left">
                      <div className="total-txt" />
                      <div className="sm-content" />
                    </div>
                    <div className="total right">
                      <div className="total-txt" />
                      <a
                        href="javascript:void(0)"
                        onClick={() => showDetailPrice()}
                        className="sm-content"
                      >
                        Back to Price
                      </a>
                    </div>
                  </div>
                </div>
              ))}

            {errorMsg && (
              <div className="alert alert-danger" role="alert">
                {errorMsg}
              </div>
            )}
          </div>
        )}

        {bookingReqData && bookingDisplayData && (
          <ReservationModule
            showModal={showModal}
            setShowModal={setShowModal}
            propertyData={props}
            bookingReqData={bookingReqData}
            bookingDisplayData={bookingDisplayData}
            paymentData={paymentData}
          />
        )}
        {/* {isAvailable ? "true" : "false"} */}
        {props?.mobview && !props?.mobiletog ? (
          <a
            // href="javascript:;"
            className={clsx(
              "button button-primary"
              // checkingAvailability && "button--loading"
            )}
            onClick={() => {
              return props?.setMobileTog(true)
            }}
          >
            <span>
              {isAvailable ? "Book Reservation" : "Check availability"}
            </span>
          </a>
        ) : (
          <a
            // href={isAvailable ? null : "javascript:;"}
            className={clsx(
              "button button-primary",
              checkingAvailability && "button--loading"
            )}
            onClick={() => {
              return isAvailable ? gocart() : checkAvailability()
            }}
          >
            <span>
              {isAvailable ? "Book Reservation" : "Check availability"}
            </span>
          </a>
        )}

        {!props?.mobview && (
          <Link
            to={`/${PageLinks.team}`}
            // href="javascript:void(0)"
            // onClick={() => submit()}
            className="button button-secondary"
          >
            <i className="icon question-icon" />{" "}
            <span className="bold">Need help?</span>{" "}
            <span className="light">Speak to one of our team here</span>
          </Link>
        )}
      </div>

      {/* <div className="border-line"></div> */}

      {/* <div className="property-detail-nego-btm text-center">
                <div className="property-detail-nego-title">Need to Sell or Let a property?</div>
                <div className="property-detail-nego-text">Get a <Link to={`/${PageLinks.intant_valuation}/`}>free instant online valuation</Link> or book one of our agents to give you a more in-depth valuation.</div>
                <Link to={`/${PageLinks.valuation}/`} className="button button-primary-light button-with-icon"><i className="icon icon-valuation-home"></i> Book a valuation</Link>
            </div> */}
    </div>
  )
}

export default PropertyDetailNego